.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.rank{
    font-size:15px;
    font-weight: bold;
    color:white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #7c0b00;
    padding: 5px 25px;
}

.winner-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.winner_dialog {
  width: 90%;
  max-width: 800px;
  max-height: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 10px;
  border: none;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.winners_page .header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  background: #7c0b00;
}
.winners_page .header_container .title_box {
  display: flex;
  align-items: center;
  gap: 20px;
}
.winners_page .header_container .title_box .title {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
}
.winners_page .header_container .close_btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
}

.winners_page .table .thead {
  background: #6b0a01;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.winners_page .table td .player_name {
  display: flex;
  align-items: center;
}
.winners_page .table td .player_name img {
  margin: 0 10px 0 0;
}
.table-container {
  overflow-x: auto;
}
